<template>
  <div>
    <img
        :src="url"
        alt=""
        class="banner-image"
       :style="[{ height: imgHeight }]" 
    />
  </div>
  
        
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      default() {
        return "";
      },
    },
  },
  data() {
    return {
      imgHeight: "",
    };
  },
  mounted() {
    // 监听窗口变化，使得轮播图高度自适应图片高度
    window.addEventListener("resize", () => {
      this.swiperHeight();
    });
    this.swiperHeight();
  },
  methods: {
    swiperHeight() {
      this.$nextTick(() => {
        this.imgHeight =
            Math.ceil((document.body.clientWidth *500) / 1920) + "PX";
        // if (400<document.body.clientWidth <= 768) {
        //   this.imgHeight = "300PX";
        // }
        if (document.body.clientWidth <= 400) {
          this.imgHeight = "100PX";
        }
      });
    },
  },
};
</script>

<style>
.banner-image {
  width: 100vw;
  object-fit: cover;
}
</style>