<template>
  <div>
    <div class="gre-mian ">
      <div>
        <myimage :url='url'></myimage>
      </div>
       <div class="gre">
          <img src="../../assets/image/GRE/zong.png" alt="">
       </div>
    </div>
  </div>
</template>

<script>
import myimage from '@/components/myimage'

export default {
  components: {
    myimage
  },
  data() {
    return {
      url:require('@/assets/image/GRE/bg.png')
    }
  },
}
</script>

<style lang="scss" scoped>
.gre-mian {
  width: 100vw;

}
.gre img{
  width: 100%;
}
</style>